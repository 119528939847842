<template>
    <BT-Blade-Item
        bladeName="supplier-invoice"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        navigation="supplier-invoices"
        :onPullSuccessAsync="pullInvoice"
        :scroll="false"
        title="Supplier Invoice">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="12">
                    <BT-Field-Company
                        :id="item.sellerID"
                        rounded
                        showAction
                        :title="item.seller.companyName">
                        <template v-slot:action>
                            <BT-Menu
                                hideHeader
                                icon="mdi-cog"
                                left
                                title="More Options">
                                <template v-slot>
                                    <v-subheader>Print</v-subheader>
                                    <BT-Print
                                        buttonClass=""
                                        :getFileName="invD => `Supplier Invoice #${invD.invoice.invoiceNumber}.pdf`"
                                        :item="item"
                                        :itemID="item.id"
                                        :onPullSuccessAsync="pullForPrint">
                                        <template v-slot:activator="{ openDialog }">
                                            <v-list-item @click="openDialog" dense>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-printer</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Print Invoice</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template v-slot="{ item, strategy }">
                                            <v-container v-if="item != null" class="pdf-page">
                                                <v-row>
                                                    <v-col cols="8">
                                                        <div style="height: 25px;" />
                                                        <v-img v-if="strategy != 'jspdf'" class="pdf-logo" :src="item.logoImageData" max-width="100px" max-height="100px" />
                                                        <v-list-item three-line>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>BILLED BY</v-list-item-subtitle>
                                                                <v-list-item-title>
                                                                    <v-icon left small>mdi-account</v-icon>
                                                                    <strong>{{ item.invoice.seller.companyName }}</strong>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="mt-5">
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>
                                                                    <v-icon left small>mdi-arrow-right-bottom</v-icon>
                                                                    BILLED TO
                                                                </v-list-item-subtitle>
                                                                <v-list-item-title>
                                                                    <v-icon left small>mdi-account</v-icon>
                                                                    <strong>{{ item.invoice.buyer.companyName }}</strong>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <h2>Invoice</h2>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Invoice #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.invoiceNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.customerOrderNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.purchaseOrderNumber }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.issuedOn | toShortDate }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item dense>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                                                <v-list-item-title>{{ item.invoice.dueOn | toShortDate }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>

                                                <!-- <v-row dense style="background-color: #192233; color: white; padding: 0px 5px;"> -->
                                                <v-row class="my-3" dense style="border-bottom: 1px solid lightgray;">
                                                    <v-col cols="2">QTY</v-col>
                                                    <v-col cols="6">DESCRIPTION</v-col>
                                                    <v-col cols="2">UNIT PRICE</v-col>
                                                    <v-col cols="2" style="text-align: right;">TOTAL</v-col>
                                                </v-row>

                                                <v-row no-gutters v-for="(lineItem, index) in item.invoice.lineItems" :key="index" style="font-size: 13px;">
                                                    <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                                    <v-col cols="6">{{ lineItem.description }}</v-col>
                                                    <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                                    <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                                                </v-row>
                                                
                                                <v-divider style="margin: 10px 0px;" />

                                                <v-row dense style="font-size: 14px;">
                                                    <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                                                    <v-col cols="3" style="text-align: right;">{{ item.invoice.subTotal | toCurrency }}</v-col>
                                                </v-row>
                                                <v-row dense style="font-size: 14px;">
                                                    <v-col cols="9" style="text-align: right;">GST:</v-col>
                                                    <v-col cols="3" style="text-align: right;">{{ item.invoice.taxTotal | toCurrency }}</v-col>
                                                </v-row>
                                                
                                                <v-divider style="margin: 10px 0px;" />
                                                
                                                <v-row>
                                                    <v-col cols="9" style="text-align: right;">Total:</v-col>
                                                    <v-col cols="3" style="text-align: right;">
                                                        <h4>{{ item.invoice.amountTotal | toCurrency }}</h4>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </template>
                                    </BT-Print>
                                </template>
                            </BT-Menu>
                        </template>
                    </BT-Field-Company>
                </v-col>
               
                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.invoiceNumber"
                        label="Invoice #" />
                </v-col>
                
                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.customerOrderNumber"
                        label="Customer Order #" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.purchaseOrderNumber"
                        label="Purchase Order #" />
                </v-col>
                
                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.issuedOn"
                        label="Issued On" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.dueOn"
                        label="Due On" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-if="item.isPaid"
                        v-model="item.paidOn"
                        label="Paid On" />
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title class="error--text">*Unpaid*</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col cols="12">
                    <v-divider class="my-1" />
                    <v-subheader>Line Items</v-subheader>
                    <BT-Table
                        :canRefresh="false"
                        :canSelect="false"
                        :headers="[
                            { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', display: true },
                            { text: 'Description', value: 'description' },
                            { text: 'Unit Price', value: 'unitPrice', textFilter: 'toCurrency', display: true },
                            { text: 'Total', value: 'total', display: true }]"
                        hideActions
                        :items="item.lineItems">
                        <template v-slot:listItem="{ item }">
                            <v-list-item-avatar>
                                <v-img :src="productLogoURL(item.itemID)" class="my-auto">
                                    <template v-slot:placeholder><v-icon class="primary--text">mdi-cube-outline</v-icon></template>
                                </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.description }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <span>{{ item.quantity | toDisplayNumber }} </span>
                                    <span v-if="item.onOrder != item.quantity" class="error--text text-decoration-line-through">  ({{ item.onOrder | toDisplayNumber }})</span>
                                    <span class="ml-2">@{{ item.unitPrice | toCurrency }}ea</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="item.unitPrice > 0 || item.quantity > 0">
                                {{ (item.unitPrice * item.quantity) | toCurrency }}
                            </v-list-item-action>
                        </template>
                        <template v-slot:quantity="{ item }">
                            <span>{{ item.quantity | toDisplayNumber }} <span class="grey--text">/ {{ item.onOrder | toDisplayNumber }}</span></span>
                        </template>
                        <template v-slot:unitPrice="{ item }">
                            @{{ item.unitPrice | toCurrency }}ea
                        </template>
                        <template v-slot:total="{ item }">
                            <span>{{ (item.quantity * item.unitPrice) | toCurrency }}</span>
                        </template>
                    </BT-Table>
                </v-col>

                <v-col cols="12">

                    <v-row no-gutters>
                        <v-spacer />
                        <div>
                            <h4 class="text-right ma-3">Subtotal: {{ item.subTotal | toCurrency }}</h4>
                            <h4 class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</h4>
                        </div>
                    </v-row>

                    <v-divider />

                    <v-row no-gutters>
                        <v-spacer />
                        
                        <div>
                            <h3 class="text-right ma-3">Total: {{ item.amountTotal | toCurrency }}</h3>
                            <h2 v-if="!item.isPaid && item.amountPaid > 0" class="text-right error--text ma-5">Amount Paid: {{ item.amountPaid | toCurrency }}</h2>
                            <h2 v-if="!item.isPaid" class="text-right warning--text ma-5">
                                Amount Due: {{ item.amountTotal - item.amountPaid | toCurrency }}
                            </h2>
                        </div>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <BT-Settings-Slider
                        :options="[
                            { title: 'Delivered Stock', value: 'stock' },
                            { title: 'Delivered Packages', value: 'packages' },
                            { title: 'Courier Orders', value: 'courier-orders' },
                            { title: 'Delivery Information', value: 'delivery' }]">
                        <template #stock>
                            <BT-Blade-Items
                                actualHeight="100%"
                                :canExportCSV="false"
                                :canSelect="false"
                                :headers="[
                                    { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', isIcon: true },
                                    { text: 'Batch', value: 'batch.batchcode' },
                                    { text: 'DOM', value: 'batch.dom', textFilter: 'toShortDate', subtitle: true, prefix: 'DOM: ' },
                                    { text: 'EXP', value: 'batch.exp', textFilter: 'toShortDate', subtitle: true, prefix: 'EXP: ' },
                                    { text: 'Product', value: 'productID', display: true, title: true }]"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                isSingle
                                :itemID="item.id"
                                navigation="transfer-archives"
                                :onPullSuccessAsync="res => { return res.transferOrderItems }">
                                <template v-slot:productID="{ item }">
                                    <BT-Entity
                                        navigation="public-products"
                                        alternateText="Not Found"
                                        itemText="productName"
                                        :itemValue="item.productID"
                                        single
                                        useLocalCache />
                                </template>
                            </BT-Blade-Items>
                        </template>
                        <template #packages>
                            <BT-Blade-Items
                                actualHeight="100%"
                                :canExportCSV="false"
                                :canSelect="false"
                                :headers="[
                                    { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber' },
                                    { text: 'Package', value: 'measurement', display: true }]"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                isSingle
                                :itemID="item.id"
                                navigation="transfer-archives"
                                :onPullSuccessAsync="res => { return res.packagedItems }"
                                showTable>
                                <template v-slot:measurement="{ item }">
                                    <BT-Entity 
                                        navigation="measurement-standards"
                                        :itemValue="item.measurementStandard"
                                        itemText="measurementName" />
                                </template>
                            </BT-Blade-Items>
                        </template>
                        <template #courier-orders>
                            <BT-Blade-Items
                                actualHeight="100%"
                                addBladeName="client-order-archive"
                                :canExportCSV="false"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                navigation="client-order-archives"
                                :onPullSuccessAsync="pullCourierOrders"
                                :params="{ includeDetails: false, consignmentID: item.id }"
                                showList>
                                <template v-slot:list="{ items }">
                                    <v-list class="overflow-y-auto">
                                        <v-card v-for="(item, ind) in items" :key="ind" :to="{ name: 'client-order-archive', params: { id: item.id }}">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <BT-Field-Company :id="item.sellerID" />
                                                </v-col>
                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.departureLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="FROM"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.destinationLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="TO"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>
                                                
                                                <v-col cols="6" sm="4">
                                                    <BT-Field-String
                                                        v-model="item.courierOrderNumber"
                                                        label="Courier Order #" />
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Field-Date
                                                        v-model="item.dueDate"
                                                        label="Due On" />
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Field-Date
                                                        v-model="item.fulfilledOn"
                                                        label="Fulfilled On"
                                                        toShortDateAndTime />
                                                </v-col>

                                            </v-row>
                                        </v-card>
                                    </v-list>
                                </template>
                            </BT-Blade-Items>
                        </template>
                        <template #delivery>
                            <BT-Blade-Items
                                actualHeight="100%"
                                :canExportCSV="false"
                                customURL="/get/ByConsignmentID"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                navigation="receival-archives"
                                :onPullSuccessAsync="pullDeliveries"
                                :params="{ includeDetails: false, id: item.id }"
                                showList>
                                <template v-slot:list="{ items }">
                                    <v-list class="overflow-y-auto">
                                        <v-card v-for="(item, ind) in items" :key="ind"> <!--:to="{ name: 'receival-archive', params: { id: item.id }}">-->
                                            <v-row no-gutters>
                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.departureLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="FROM"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>

                                                <v-col cols="6" sm="4">
                                                    <BT-Entity 
                                                        navigation="public-locations"
                                                        :itemValue="item.destinationLocationID"
                                                        single
                                                        useLocalCache>
                                                        <template v-slot="{ item }">
                                                            <BT-Field-Address
                                                                label="TO"
                                                                :value="item" />
                                                        </template>
                                                    </BT-Entity>
                                                </v-col>

                                                <v-col cols="6" sm="4" class="d-flex align-center">
                                                    <div class="warning--text ml-4">
                                                        <span v-if="!item.isPickup && item.isAutoDeliver">**Auto Pick Up</span>
                                                        <span v-else-if="item.deliveredByUserID == null && item.deliveredOn != null">**Auto Deliver</span>
                                                        <span v-else-if="item.isPickup">**Pick Up</span>
                                                        <span v-else>**Deliver</span>
                                                    </div>
                                                    <BT-Btn
                                                        buttonClass="primary--text"
                                                        icon="mdi-open-in-new"
                                                        title="Open"
                                                        :to="{ name: 'receival-archive', params: { id: item.id }}" />
                                                </v-col>
                                                
                                                <v-col cols="12" sm="6">
                                                    <v-divider class="my-2" />
                                                    <v-subheader>
                                                        <v-icon left small>mdi-draw</v-icon>
                                                        Proof Of Delivery
                                                        <v-spacer />
                                                        <span>Signer: {{ item.receiverName || '(Unknown)' }}</span>
                                                    </v-subheader>
                                                    <BT-Signature
                                                        class="ml-4 mb-1"
                                                        height="150px"
                                                        v-model="item.proofOfDelivery" />
                                                    <span v-if="item.deliveredOn != null" class="ml-4 text-caption">
                                                        <span>By</span>
                                                        <BT-Entity
                                                            alternateText=" unknown "
                                                            navigation="public-users"
                                                            inline
                                                            :itemValue="item.deliveredByUserID"
                                                            single
                                                            textFilter="toUserLine"
                                                            useLocalCache />
                                                        <span>on {{ item.deliveredOn | toShortDateAndTime }}</span>
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-divider class="my-2" />
                                                    <v-subheader>
                                                        <v-icon left small>mdi-camera</v-icon>
                                                        Photo
                                                        <v-spacer />
                                                        <BT-Btn
                                                            v-if="item.lat != null && item.lng != null"
                                                            :href="`https://maps.google.com/?q=${item.lat},${item.lng}`"
                                                            inline
                                                            label="Check Coordinates"
                                                            leftIcon="mdi-map-marker-radius"
                                                            small
                                                            target="_blank" />
                                                    </v-subheader>
                                                    <BT-Photo
                                                        height="150px"
                                                        :icon="null"
                                                        :id="item.id" />
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-list>
                                </template>
                            </BT-Blade-Items>
                        </template>
                    </BT-Settings-Slider>
                    <!-- <BT-Preload
                        label="More Info">
                        <v-card>
                            <v-tabs v-model="panelV" fixed-tabs>
                                <v-tab>Stock</v-tab>
                                <v-tab>Packages</v-tab>
                                <v-tab>Courier Orders</v-tab>
                                <v-tab>Deliveries</v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="panelV" class="ma-4">
                                <v-tab-item>
                                    <BT-Blade-Items
                                        :canExportCSV="false"
                                        :canSelect="false"
                                        :headers="[
                                            { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', isIcon: true },
                                            { text: 'Batch', value: 'batch.batchcode' },
                                            { text: 'DOM', value: 'batch.dom', textFilter: 'toShortDate', subtitle: true, prefix: 'DOM: ' },
                                            { text: 'EXP', value: 'batch.exp', textFilter: 'toShortDate', subtitle: true, prefix: 'EXP: ' },
                                            { text: 'Product', value: 'productID', display: true, title: true }]"
                                        hideActions
                                        hideBladeHeader
                                        hideHeader
                                        isSingle
                                        :itemID="item.id"
                                        navigation="transfer-archives"
                                        :onPullSuccessAsync="res => { return res.transferOrderItems }">
                                        <template v-slot:productID="{ item }">
                                            <BT-Entity
                                                navigation="public-products"
                                                alternateText="Not Found"
                                                itemText="productName"
                                                :itemValue="item.productID"
                                                single
                                                useLocalCache />
                                        </template>
                                    </BT-Blade-Items>
                                </v-tab-item>
                                <v-tab-item>
                                    <BT-Blade-Items
                                        :canExportCSV="false"
                                        :canSelect="false"
                                        :headers="[
                                            { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber' },
                                            { text: 'Package', value: 'measurement', display: true }]"
                                        hideActions
                                        hideBladeHeader
                                        hideHeader
                                        isSingle
                                        :itemID="item.id"
                                        navigation="transfer-archives"
                                        :onPullSuccessAsync="res => { return res.packagedItems }"
                                        showTable>
                                        <template v-slot:measurement="{ item }">
                                            <BT-Entity 
                                                navigation="measurement-standards"
                                                :itemValue="item.measurementStandard"
                                                itemText="measurementName" />
                                        </template>
                                    </BT-Blade-Items>
                                </v-tab-item>
                                <v-tab-item>
                                    <BT-Blade-Items
                                        addBladeName="client-order-archive"
                                        :canExportCSV="false"
                                        hideActions
                                        hideBladeHeader
                                        hideHeader
                                        navigation="client-order-archives"
                                        :onPullSuccessAsync="pullCourierOrders"
                                        :params="{ includeDetails: false, consignmentID: item.id }"
                                        showList>
                                        <template v-slot:list="{ items }">
                                            <v-list class="overflow-y-auto">
                                                <v-card v-for="(item, ind) in items" :key="ind" :to="{ name: 'client-order-archive', params: { id: item.id }}">
                                                    <v-row no-gutters>
                                                        <v-col cols="12">
                                                            <BT-Field-Company :id="item.sellerID" />
                                                        </v-col>
                                                        <v-col cols="6" sm="4">
                                                            <BT-Entity 
                                                                navigation="public-locations"
                                                                :itemValue="item.departureLocationID"
                                                                single
                                                                useLocalCache>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Address
                                                                        label="FROM"
                                                                        :value="item" />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>

                                                        <v-col cols="6" sm="4">
                                                            <BT-Entity 
                                                                navigation="public-locations"
                                                                :itemValue="item.destinationLocationID"
                                                                single
                                                                useLocalCache>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Address
                                                                        label="TO"
                                                                        :value="item" />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                        
                                                        <v-col cols="6" sm="4">
                                                            <BT-Field-String
                                                                v-model="item.courierOrderNumber"
                                                                label="Courier Order #" />
                                                        </v-col>

                                                        <v-col cols="6" sm="4">
                                                            <BT-Field-Date
                                                                v-model="item.dueDate"
                                                                label="Due On" />
                                                        </v-col>

                                                        <v-col cols="6" sm="4">
                                                            <BT-Field-Date
                                                                v-model="item.fulfilledOn"
                                                                label="Fulfilled On"
                                                                toShortDateAndTime />
                                                        </v-col>

                                                    </v-row>
                                                </v-card>
                                            </v-list>
                                        </template>
                                    </BT-Blade-Items>
                                </v-tab-item>
                                <v-tab-item>
                                    <BT-Blade-Items
                                        :canExportCSV="false"
                                        customURL="/get/ByConsignmentID"
                                        hideActions
                                        hideBladeHeader
                                        hideHeader
                                        navigation="receival-archives"
                                        :onPullSuccessAsync="pullDeliveries"
                                        :params="{ includeDetails: false, id: item.id }"
                                        showList>
                                        <template v-slot:list="{ items }">
                                            <v-list class="overflow-y-auto">
                                                <v-card v-for="(item, ind) in items" :key="ind">
                                                    <v-row no-gutters>
                                                        <v-col cols="6" sm="4">
                                                            <BT-Entity 
                                                                navigation="public-locations"
                                                                :itemValue="item.departureLocationID"
                                                                single
                                                                useLocalCache>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Address
                                                                        label="FROM"
                                                                        :value="item" />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>

                                                        <v-col cols="6" sm="4">
                                                            <BT-Entity 
                                                                navigation="public-locations"
                                                                :itemValue="item.destinationLocationID"
                                                                single
                                                                useLocalCache>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Address
                                                                        label="TO"
                                                                        :value="item" />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>

                                                        <v-col cols="6" sm="4" class="d-flex align-center">
                                                            <div class="warning--text ml-4">
                                                                <span v-if="!item.isPickup && item.isAutoDeliver">**Auto Pick Up</span>
                                                                <span v-else-if="item.deliveredByUserID == null && item.deliveredOn != null">**Auto Deliver</span>
                                                                <span v-else-if="item.isPickup">**Pick Up</span>
                                                                <span v-else>**Deliver</span>
                                                            </div>
                                                            <BT-Btn
                                                                buttonClass="primary--text"
                                                                icon="mdi-open-in-new"
                                                                title="Open"
                                                                :to="{ name: 'receival-archive', params: { id: item.id }}" />
                                                        </v-col>
                                                        
                                                        <v-col cols="12" sm="6">
                                                            <v-divider class="my-2" />
                                                            <v-subheader>
                                                                <v-icon left small>mdi-draw</v-icon>
                                                                Proof Of Delivery
                                                                <v-spacer />
                                                                <span>Signer: {{ item.receiverName || '(Unknown)' }}</span>
                                                            </v-subheader>
                                                            <BT-Signature
                                                                class="ml-4 mb-1"
                                                                height="150px"
                                                                v-model="item.proofOfDelivery" />
                                                            <span v-if="item.deliveredOn != null" class="ml-4 text-caption">
                                                                <span>By</span>
                                                                <BT-Entity
                                                                    alternateText=" unknown "
                                                                    navigation="public-users"
                                                                    inline
                                                                    :itemValue="item.deliveredByUserID"
                                                                    single
                                                                    textFilter="toUserLine"
                                                                    useLocalCache />
                                                                <span>on {{ item.deliveredOn | toShortDateAndTime }}</span>
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="6">
                                                            <v-divider class="my-2" />
                                                            <v-subheader>
                                                                <v-icon left small>mdi-camera</v-icon>
                                                                Photo
                                                                <v-spacer />
                                                                <BT-Btn
                                                                    v-if="item.lat != null && item.lng != null"
                                                                    :href="`https://maps.google.com/?q=${item.lat},${item.lng}`"
                                                                    inline
                                                                    label="Check Coordinates"
                                                                    leftIcon="mdi-map-marker-radius"
                                                                    small
                                                                    target="_blank" />
                                                            </v-subheader>
                                                            <BT-Photo
                                                                height="150px"
                                                                :icon="null"
                                                                :id="item.id" />
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-list>
                                        </template>
                                    </BT-Blade-Items>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </BT-Preload> -->
                </v-col>
            </v-row>
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';
import { getImageData } from '~helpers';

export default {
    name: 'Supplier-Invoice-Blade',
    components: {
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTPhoto: () => import('~components/BT-Photo.vue'),
        BTPrint: () => import('~components/BT-Print.vue'),
        BTSignature: () => import('~components/BT-Signature.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            amountPaid: { v: 0 },
            msg: null,
            panelV: 0,
            isStripeLoading: false,
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        paid(item) {
            item.amountPaid = this.amountPaid.v;
        },
        prepItem(item) {
            item.lineItems = item.lineItems.orderBy('sortNumber');
        },
        pullCourierOrders(list) {
            var r = list.sort(firstBy(x => x.fulfilledOn));
            return r;
        },
        pullDeliveries(list) {
            var r = list.sort(firstBy(x => x.deliveredOn));
            return r;
        },
        async pullForPrint(item, id) {
            var uri = this.companyLogoURL(item.sellerID);
            var logoImgData = await getImageData(uri, false);
            
            return {
                delivery: null,
                id: id,
                invoice: item,
                logoImageData: logoImgData
            }
        },
        pullInvoice(item) {
            item.lineItems.sort(firstBy(x => x.sortNumber));
            return item;
        }
    }
}
</script>